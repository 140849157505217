import React, { useEffect, useState } from "react";
import { getUrlList } from "../../Service/blogService";
import { deleteUrl } from "../../Service/urlService";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";

export default function PostList() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [copiedUrls, setCopiedUrls] = useState([]);

  const fetchApiData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await getUrlList(token);
        if (response.success) {
          setData(response.results);

          setCopiedUrls(response.results.map(() => false));
        } else {
          toast.error(response.message || "Failed to fetch url data");
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error fetching url data:", error);
      toast.error("Failed to fetch url data");
    }
  };

  const handleCopy = (url, index) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("URL copied to clipboard");
        // Update the copied state for the specific index
        setCopiedUrls((prevCopiedUrls) => {
          const newCopiedUrls = [...prevCopiedUrls];
          newCopiedUrls[index] = true;
          return newCopiedUrls;
        });
      })
      .catch(() => toast.error("Failed to copy URL"));
  };

  const handleDelete = async (_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this url?"
    );
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }

      const response = await deleteUrl(token, _id);
      if (response.success) {
        fetchApiData();
        toast.success(response.message || "url deleted successfully");
      } else {
        toast.error(response.message || "Failed to delete url");
      }
    } catch (error) {
      console.error("Error deleting url:", error);
      toast.error("Failed to delete url");
    }
  };

  useEffect(() => {
    fetchApiData();
  }, []);

  return (
    <div className="container-fluid">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Sr No</th>
            <th scope="col">Date</th>
            <th scope="col">Image</th>
            <th scope="col">Image Url</th>
            <th scope="col">Image Alt</th>
            <th scope="col">Usages</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item._id}>
              <th>{index + 1}</th>
              <td>{item?.date}</td>
              <td>
                <img
                  className="postList-img"
                  src={item?.img}
                  alt={item?.imgAlt}
                />
              </td>
              <td>{item?.img}</td>
              <td>{item?.imgAlt}</td>
              <td>{item?.usages}</td>
              <td>
                <button
                  onClick={() => handleCopy(item.img, index)}
                  className={`btn btn-primary ${
                    copiedUrls[index] ? "disabled" : ""
                  }`}
                  disabled={copiedUrls[index]}>
                  {copiedUrls[index] ? "URL Copied" : "Copy URL"}
                </button>
              </td>
              <td>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDelete(item._id)}>
                  <MdDelete />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
