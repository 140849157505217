import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getBlogById, updateBlog } from "../Service/blogService";
import { toast } from "react-hot-toast";
import JoditEditor from "jodit-react";

export default function PostEdit() {
  const { title } = useParams();
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const loadOldData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate(`/login`);
        return;
      }
      const response = await getBlogById(token, title);
      if (response.success) {
        setBlogData(response.result);
        setLoading(false);
      } else {
        toast.error("Failed to load data.");
      }
    } catch (error) {
      console.error("Error fetching blog data:", error);
      toast.error("An error occurred while fetching data.");
    }
  };

  useEffect(() => {
    loadOldData();
  }, [title]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "metaTitle" ||
      name === "metaDescription" ||
      name === "metaKeywords"
    ) {
      setBlogData((prevData) => ({
        ...prevData,
        meta: {
          ...prevData.meta,
          [name.slice(4).toLowerCase()]: value,
        },
      }));
    } else {
      setBlogData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleContentChange = (newContent) => {
    setBlogData((prevData) => ({
      ...prevData,
      content: newContent,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate(`/login`);
        return;
      }
      const response = await updateBlog(token, title, blogData);
      if (response.success) {
        toast.success(response.message);
        setTimeout(() => {
          navigate(`/admin/postlist`);
        }, 2000);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error updating blog:", error);
      toast.error("An error occurred while updating the blog.");
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header bg-success text-white">
              Edit Your News
            </div>
            <div className="card-body">
              <form onSubmit={handleUpdate}>
                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="title" className="form-label">
                      Title <span className="require-field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={blogData.title || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="img" className="form-label">
                      Image <span className="require-field">*</span>
                    </label>
                    <img
                      src={blogData.img || "placeholder-image-url"}
                      alt="Image_Preview"
                      className="img-fluid mb-2"
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="img"
                      value={blogData.img || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-6">
                    <label htmlFor="category" className="form-label">
                      Category <span className="require-field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="category"
                      value={blogData.category || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="keywordUrl" className="form-label">
                      Keyword Url <span className="require-field">*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      name="keywordUrl"
                      value={blogData.keywordUrl || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="imgAlt" className="form-label">
                      Image Alt <span className="require-field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="imgAlt"
                      value={blogData.imgAlt || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <h5>
                    Meta Tag <span>(optional)</span>
                  </h5>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="metaTitle" className="form-label">
                        Meta Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="metaTitle"
                        value={blogData.meta.title || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="metaDescription" className="form-label">
                        Meta Description
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="metaDescription"
                        value={blogData.meta.description || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="metaKeywords" className="form-label">
                        Meta Keywords
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="metaKeywords"
                        value={
                          blogData.meta.keywords
                            ? blogData.meta.keywords.join(", ")
                            : ""
                        }
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: "metaKeywords",
                              value: e.target.value
                                .split(",")
                                .map((keyword) => keyword.trim()),
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="content" className="form-label">
                    Content <span className="require-field">*</span>
                  </label>
                  <JoditEditor
                    value={blogData.content || ""}
                    onChange={handleContentChange}
                  />
                </div>
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={isUpdating}>
                    {isUpdating ? "Updating..." : "Update Blog"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
