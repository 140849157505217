// apiService.js
import api from "./apiHelper";

export const getBlog = async () => {
  try {
    const response = await api.get(`api/v1/blog/get-blog`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "Posting Blog failed");
    } else {
      throw new Error(error.message || "internal server error");
    }
  }
};
export const deleteBlog = async (token, _id) => {
  try {
    const response = await api.delete(
      `api/v1/blog/delete-blog/${_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "delete Blog failed");
    } else {
      throw new Error(error.message || "internal server error");
    }
  }
};
export const getBlogById = async (token, _id) => {
  try {
    const response = await api.get(
      `api/v1/blog/get-blog/${_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "fetch Blog failed");
    } else {
      throw new Error(error.message || "internal server error");
    }
  }
};
export const updateBlog = async (token, _id, newData) => {
  try {
    const response = await api.put(`api/v1/blog/put-blog/${_id}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "fetch Blog failed");
    } else {
      throw new Error(error.message || "internal server error");
    }
  }
};
export const postBlog = async (token, formData) => {
  try {
    console.log(token);
    const response = await api.post(`api/v1/blog/post-blog`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "Posting Blog failed");
    } else {
      throw new Error(error.message || "internal server error");
    }
  }
};
//

export const postUrl = async (token, formData) => {
  try {
    const response = await api.post(`api/v1/blog/upload-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "Posting Blog failed");
    } else {
      throw new Error(error.message || "internal server error");
    }
  }
};

export const getUrlList = async (token) => {
  try {
    const response = await api.get(
      `api/v1/blog/get-url-list`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "Posting Blog failed");
    } else {
      throw new Error(error.message || "internal server error");
    }
  }
};
