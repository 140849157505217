import React from "react";
import PostBlog from "./PostBlog";

export default function Home() {
  return (
    <>
      <PostBlog />
    </>
  );
}
