import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Component/App";
import { Toaster } from "react-hot-toast";
import "../src/asset/style.css";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Toaster />
    <App />
  </>
);
