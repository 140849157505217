import React, { useState, useRef } from "react";
import { postUrl } from "../../Service/blogService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const GetUrl = () => {
  const [imgAlt, setImgAlt] = useState("");
  const [image, setImage] = useState(null);
  const [usages, setUsages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (!token) {
      navigate(`/login`);
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("img", image);
      formData.append("imgAlt", imgAlt);
      formData.append("usages", usages);

      const response = await postUrl(token, formData);
      const { success, message, PropsUrl } = response;

      if (success) {
        toast.success(message || "Image uploaded successfully");
        setUrl(PropsUrl);
        setImgAlt("");
        setImage(null);
        fileInputRef.current.value = "";
        setCopied(false);
      } else {
        toast.error(message || "Failed to upload image");
      }
    } catch (error) {
      toast.error(
        "An error occurred while uploading the image: " + error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("URL copied to clipboard");
        setCopied(true);
      })
      .catch(() => toast.error("Failed to copy URL"));
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header bg-success text-white text-center">
              Upload Your Image Here
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="img" className="form-label">
                        Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="img"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                        disabled={loading}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="imgAlt" className="form-label">
                        Image Alt Text <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="imgAlt"
                        value={imgAlt}
                        onChange={(e) => setImgAlt(e.target.value)}
                        disabled={loading}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="usages" className="form-label">
                      Usages <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="usages"
                      value={usages}
                      onChange={(e) => setUsages(e.target.value)}
                      disabled={loading}
                      required
                    />
                  </div>
                </div>
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={loading}>
                    {loading ? "Uploading..." : "Upload Image"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row justify-content-center">
        <div className="col-md-8">
          {url ? (
            <div className="card-body">
              <p className="geturlcls">{url}</p>
              <button
                onClick={handleCopy}
                className={`btn btn-primary ${copied ? "disabled" : ""}`}
                disabled={copied}>
                {copied ? "URL Copied" : "Copy URL"}
              </button>
            </div>
          ) : (
            <p className="card-body geturlcls">No URL Found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default GetUrl;
