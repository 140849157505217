import api from "./apiHelper";

export const deleteUrl = async (token, _id) => {
  try {
    const response = await api.delete(
      `api/v1/blog/url-delete/${_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "delete Url failed");
    } else {
      throw new Error(error.message || "internal server error");
    }
  }
};
