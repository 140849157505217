import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { verifyToken } from "../Service/authService";
import toast from "react-hot-toast";
const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const verifyTokenfn = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setIsAuthenticated(false);
      } else {
        try {
          const response = await verifyToken(token);
          if (response.success === true) {
            setIsAuthenticated(response.success);
          }
        } catch (error) {
          console.error("Token verification failed:", error);
          toast.error("UnAuthenticated Admin");
          setIsAuthenticated(false);
        }
      }
    };

    verifyTokenfn();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
