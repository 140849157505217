import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Navbar from "./Navbar";
import PrivateRoute from "./PrivateRoute";
import PostBlog from "./PostBlog";
import PostList from "./PostList";
import PostEdit from "./PostEdit";

import GetUrl from "./Upload/GetUrl";
import UrlList from "./Upload/UrlList";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Home />} />

            <Route path="/admin/post" element={<PostBlog />} />
            <Route path="/admin/postlist" element={<PostList />} />
            <Route path="/admin/geturl" element={<GetUrl />} />
            <Route path="/admin/urllist" element={<UrlList />} />
            <Route path="/admin/edit-blog/:title" element={<PostEdit />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
